// progress bar
import NProgress from 'nprogress';
import { message } from 'ant-design-vue';
import { setDocumentTitle, timeFix } from '@/utils/illuminate';
import ls from '@/utils/ls';

// progress bar custom style
import '@/components/NProgress/nprogress.scss';

import { UserStoreIndexList, startLoopUserDetail, startMessageLoop, stopMessageLoop, stopLoopUserDetail } from '@/store/modules/user';

import { CODE_LIST } from '@/utils/request';
import router from './index';
import store from '../store';

// NProgress Configuration
NProgress.configure({ showSpinner: false });

// 无需授权页面
const whiteList = ['bus', 'auth.login', 'auth.login_by_pwd', 'auth.login_by_code', 'auth.forgot_pwd', 'auth.chose_enterprise', 'auth.register', 'auth.register_result'];

const getParams = () => {
  const url: Array<string> = window.location.href.split('?');
  const result = url.length > 1 ? url[1] : '';
  const obj: any = {};
  result.split('&').forEach(i => {
    const v: Array<string> = i.split('=');
    if (v.length < 2) return;
    obj[v[0]] = v[1];
  });
  return obj;
};
const loginSuccess = async (next: any) => {
  try {
    await store.dispatch('User/getUserDetail');
    await store.dispatch('User/getInviteUserNum');
    await store.dispatch('User/getUserMenu');
    startLoopUserDetail();
    startMessageLoop();

    message.success(`${timeFix()}，欢迎回来`);
    next({ name: store.state.User!.sideMenu[0].name || 'main.overview', query: {} });
  } catch (e) {
    next({ name: 'auth.login_by_code', query: {} });
  }
};

router.beforeEach(async (to: any, from, next) => {
  // start progress bar
  NProgress.start();
  to.meta && (typeof to.meta.menuTitle !== 'undefined' && setDocumentTitle(`${to.meta.title || to.meta.menuTitle} - 能链企服`));
  const token = ls.get(UserStoreIndexList.ACCESS_TOKEN);

  // 如果是登录页面销毁token
  if (to.name === 'auth.login' && !!from.name) {
    ls.remove(UserStoreIndexList.ACCESS_TOKEN);
    next();
    return true;
  }

  // 跳转到登陆页面
  if (!token && !whiteList.includes(to.name!)) {
    // if current page is login will not trigger afterEach hook, so manually handle it
    NProgress.done();
    next({ name: 'auth.login' });
    return false;
  }
  
  const params = getParams();
  if (params.secretCode && to.name === 'auth.login') {
    ls.remove(UserStoreIndexList.ACCESS_TOKEN);
    stopLoopUserDetail();
    stopMessageLoop();
    // 南京银行静默登录
    const res = await store.dispatch('User/authlogin', { secretCode: params.secretCode });
    if (res.code !== 200) {
      return next({ name: 'auth.login_by_code', query: {} });
    }
    await loginSuccess(next);
    return true;
  }

  // 白名单放行
  if (!token && whiteList.includes(to.name!)) {
    NProgress.done();
    next();
    return true;
  }

  // 已授权 且 已经取得菜单权限 情况下 , 登陆页面跳转用户工作区
  if (to.name === 'auth.login' && store.state.User!.permissions.length > 0) {
    NProgress.done();
    next({ name: 'main.overview' });
    return false;
  }

  // 已经拿到菜单权限数据直接放行
  if (store.state.User!.permissions.length > 0) {
    NProgress.done();
    next();
    return true;
  }

  // 如本地尚未获取菜单权限用户权限

  // 读取用户可访问的路由表
  return store.dispatch('User/getUserMenu')
    .then(() => {
      NProgress.done();

      // 动态添加可访问路由
      const redirect = decodeURIComponent(from.query.redirect as string || to.path);


      if (to.path === redirect) {
        // hack方法 确保 addRoutes 已完成 ,set the replace: true so the navigation will not leave a history record
        next({ ...to, replace: true });
        return false;
      }

      // 跳转到目的路由
      next({ path: redirect });
      return false;
    })
    .catch(e => {
      // 拉取权限失败 退出登陆
      if ([
        CODE_LIST.TOKEN_INVALID,
        CODE_LIST.USER_NO_EXIST,
        CODE_LIST.SESSION_NO_AVAILABLE,
        CODE_LIST.USER_LOCKED,
      ].includes(e.code)) {
        message.error(e.message);
      } else {
        message.error('请求用户信息失败，请重试');
      }

      return store.dispatch('User/logout')
        .finally(() => {
          NProgress.done();
          next({ name: 'auth.login' });
        });
    });
});
router.afterEach(() => {
  NProgress.done(); // finish progress bar
});
